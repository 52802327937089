<template>
  <div app class="g blue" style="background-color: #f2f2f2">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>Panel de administración</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab v-for="(item, index) in items" :key="index">
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in items" :key="index">
        <component v-bind:is="item.component"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import BlogPost from "@/components/blog/Create.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BlogPost,
  },
  data() {
    return {
      tab: null,
      items: [{ name: "Blog", component: "BlogPost" }],
    };
  },
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>
