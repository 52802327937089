<template>
  <div class="example" style="background-color: #fafafa">
    <v-container class="mt-8">
      <v-card class="pa-3">
        <h3>Crear un nuevo Post</h3>
        <v-row class="mt-5">
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              label="Título del post"
              v-model="postTitle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field dense label="Autor" v-model="postAuthor"></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <input type="file" class="d-none" @change="selectImage" ref="boton" />
            <v-btn @click.prevent="$refs.boton.click()">
              <v-icon>mdi-image</v-icon> Agregar Imagen</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-textarea
              label="Descripción breve"
              rows="3"
              outlined
              dense
              auto-grow
              v-model="postDescription"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container>
      <quill-editor
        class="editor"
        ref="myTextEditor"
        :value="content"
        :options="editorOption"
        @change="onEditorChange"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
      />
    </v-container>
    <div class="py-8"></div>
    <v-container>
      <v-btn @click="uploadPost">Subir Post</v-btn>
    </v-container>
    <div class="py-12"></div>
  </div>
</template>

<script>
import dedent from "dedent";
import hljs from "highlight.js";
import debounce from "lodash/debounce";
import { quillEditor } from "vue-quill-editor";
import "highlight.js/styles/tomorrow.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { db } from "@/plugins/firebase.js";
import slugify from "slugify";

export default {
  name: "CreatePost",
  components: {
    quillEditor,
  },
  data() {
    return {
      postTitle: "",
      postAuthor: "",
      postDescription: "",
      postImage: "",
      postImageURL: "",
      slug: "",
      editorOption: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            ["link", "video"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            ["clean"],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      content: dedent` `,
    };
  },
  methods: {
    onEditorChange: debounce(function (value) {
      this.content = value.html;
    }, 466),
    onEditorBlur(editor) {
      return editor;
    },
    onEditorFocus(editor) {
      return editor;
    },
    onEditorReady(editor) {
      return editor;
    },
    async uploadPost() {
      var utc = new Date().toJSON().slice(0, 10).replace(/-/g, "/");

      try {
        this.slug = slugify(this.postTitle, {
          replacement: "-",
          remove: /[$*_+~.()'"!-:@]/g,
          lower: true,
        });
        const response = await db.collection("blog").add({
          title: this.postTitle,
          content: this.content,
          author: this.postAuthor,
          description: this.postDescription,
          slug: this.slug,
          image: "",
          date: utc,
        });
        console.log(response);
        this.content = dedent` `;
        this.postTitle = "";
        this.postAuthor = "";
        this.postDescription = "";
      } catch (error) {
        console.log(error);
      }
    },
    selectImage(e) {
      if (this.galeryPreview.length < 3) {
        this.postImage = e.target.files[0];
        this.postImageURL = URL.createObjectURL(this.anotherImage);
      }
    },
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill;
    },
    contentCode() {
      return hljs.highlightAuto(this.content).value;
    },
  },
};
</script>

<style lang="scss" scoped>
.example {
  display: flex;
  flex-direction: column;
  .editor {
    height: 20rem;
  }
  .output {
    width: 100%;
    height: 20rem;
    margin: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
    resize: vertical;
    &.code {
      padding: 1rem;
      height: 16rem;
    }
    &.ql-snow {
      border-top: none;
      height: 20rem;
    }
  }
}
</style>
